import { Theme } from '@mui/material';
import Color from 'color';

export const styles = (theme: Theme) => ({
  root: {
    padding: '45px',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      padding: '15px',
    },
  },
  pageTitle: {
    fontSize: '3rem',
    marginBottom: '40px',
  },
  instruction: {
    marginBottom: '20px',
    color: Color(theme.palette.grayScale.main).alpha(0.6).hsl().toString(),
  },
  asideActions: {
    display: 'none',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      flexDirection: 'row',
      display: 'flex',
      flex: 'none',
      marginBottom: '23px',
    },
  },
  logo: {
    width: '25px',
    justifyContent: 'center',
    display: { mobile: 'block', tablet: 'none' },
  },
  resources: {
    padding: '24px 20px',
    fontSize: '0.875rem',
    display: 'flex',
    width: { tablet: '550px', mobile: '100%' },
    gap: { tablet: '36px', mobile: '11px' },
  },
  resourcesImg: {
    padding: { mobile: '5px' },
    width: { tablet: '400px', mobile: '175px' },
    height: { tablet: '188px', mobile: '93px' },
    display: 'flex',
    margin: { mobile: 'auto 0', tablet: '0' },
  },
  details: {
    fontSize: '1.25rem',
    fontWeight: '700',
    paddingLeft: '20px',
  },
  img: {
    width: '100%',
  },
  subtitle: {
    color: Color(theme.palette.grayScale.main).alpha(0.6).hsl().toString(),
    marginBottom: '20px',
    fontSize: '1rem',
    fontWeight: 'normal',
    marginTop: 10,
  },
  cardLink: {
    display: 'flex',
    textDecoration: 'none',
  },
  answer: {
    fontSize: '1rem',
  },
});

export default styles;
