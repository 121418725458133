import React, { useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import 'survey-core/defaultV2.css';
import { StylesManager, Model } from 'survey-core';
import { useAuthState } from 'core/contexts/authentication.context';
import MobileHeaderComponent from 'components/MobileHeader/MobileHeader.component';
import NotificationComponent from 'components/Notification/Notification.component';
import { Survey } from 'survey-react-ui';
import useStyles from './CommunityResources.container.styles';
import './CommunityResources.css';
import CommunitySurveyService from '../../core/services/support.service';
import { get } from 'lodash';

StylesManager.applyTheme('defaultV2');

const myCss = {
  navigationButton: 'bc',
  body: 'bod',
  question: {
    number: 'bt',
    withFrame: 'withFrame',
  },
  checkbox: {
    itemControl: 'bc',
    materialDecorator: '',
  },
  radiogroup: {
    itemControl: 'bt',
    materialDecorator: '',
  },
};
const surveyJSON = {
  title: '',
  description:
    'Complete these three questions to help us understand your needs and get connected to community resources : ',
  logoPosition: 'right',
  pages: [
    {
      name: 'page1',
      elements: [
        {
          type: 'radiogroup',
          name: 'worriedAboutLosingHousing',
          title: 'Are you worried about losing your housing?',
          isRequired: true,
          choices: ['Yes', 'No', 'I choose not to answer this question'],
        },
        {
          type: 'checkbox',
          name: 'hasTransportationKeptYouFrom',
          title:
            'Has lack of transportation kept you from medical appointments, meetings, work or from getting things needed for daily living? Check all that apply.',
          isRequired: true,
          choices: [
            'Yes, It has kept me from medical appointments',
            'Yes, It has kept me from non-medical meetings, appointments, work or from getting things I need',
            'No',
            'I choose not to answer this question',
          ],
        },
        {
          type: 'checkbox',
          name: 'unabletoFindtheFollowing',
          title:
            'In the past year, have you or any family members you live with have been unable to get any of the following when it was really needed? check all that apply.',
          choices: [
            'Food',
            'Utilities',
            'Medicine or Any Healthcare (Medical, Dental, Mental Health, Vision)',
            'Phone',
            'Clothing',
            'Child Care',
            'Other',
            'I choose not to answer this question',
          ],
        },
      ],
    },
    {
      name: 'page2',
      title: false,
      elements: [
        {
          type: 'radiogroup',
          name: 'canSomeoneReachOutToAssist',
          isRequired: true,
          title: 'Can someone reach out to you to assist with your needs?',
          choices: ['Yes', 'No'],
          hideNumber: true,
          visibleIf:
            "{worriedAboutLosingHousing} = 'Yes' || {hasTransportationKeptYouFrom} = ['Yes, It has kept me from medical appointments'] || {hasTransportationKeptYouFrom} = ['Yes, It has kept me from non-medical meetings, appointments, work or from getting things I need'] || {unabletoFindtheFollowing} = ['Food'] || {unabletoFindtheFollowing} = ['Utilities'] || {unabletoFindtheFollowing} = ['Medicine or Any Healthcare (Medical, Dental, Mental Health, Vision)'] || {unabletoFindtheFollowing} = ['Phone'] || {unabletoFindtheFollowing} = ['Clothing'] || {unabletoFindtheFollowing} = ['Childcare'] || {unabletoFindtheFollowing} = ['Other']",
        },
      ],
    },
    {
      name: 'page3',
      title: false,
      elements: [
        {
          type: 'checkbox',
          name: 'bestWayToReachYou',
          isRequired: true,
          title: 'What is the best way to reach you?',
          choices: ['Phone call', 'Text', 'Email'],
          hideNumber: true,
          visibleIf: "{canSomeoneReachOutToAssist} = 'Yes'",
        },
      ],
    },
    {
      name: 'page5',
      title: false,
      elements: [
        {
          type: 'checkbox',
          name: 'bestTimeOfDayToReachYou',
          isRequired: true,
          title: 'What is the best time of day to reach you?',
          choices: [
            'Morning (8am-11am)',
            'Early Afternoon (11am-2pm)',
            'Late Afternoon (2pm-5pm)',
          ],
          hideNumber: true,
          visibleIf: "{canSomeoneReachOutToAssist} = 'Yes'",
        },
        {
          type: 'expression',
          name: 'note',
          title: 'Thank you! One of our care navigators will reach out to you.',
          description: '',
          hideNumber: true,
          visibleIf: "{canSomeoneReachOutToAssist} = 'Yes'",
        },
      ],
    },
    {
      name: 'page6',
      title: false,
      elements: [
        {
          type: 'expression',
          name: 'note',
          title: 'Thank you! ',
          description:
            'If at a future time you find yourself in need of assistance with food, housing, or other needs call 2-1-1 on your phone or click the "Community Care" button on the left to get connected with a care navigator.',
          hideNumber: true,
          visibleIf:
            "{worriedAboutLosingHousing} = 'No' && {hasTransportationKeptYouFrom} = ['No'] && {unabletoFindtheFollowing} = ['I choose not to answer this question'] ",
        },
      ],
    },
    {
      name: 'page7',
      elements: [
        {
          type: 'expression',
          name: 'thankyou',
          title:
            'We thank you for your time spent taking this survey. Your response has been recorded.',
          hideNumber: true,
        },
      ],
    },
  ],
  widthMode: 'responsive',
};

function CommunityResources() {
  const survey = new Model(surveyJSON);
  const { user } = useAuthState();
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(theme);
  const [emailSucceeded, setEmailSucceeded] = useState<boolean>(false);
  const [emailFailed, setEmailFailed] = useState<boolean>(false);

  const submitSurveyResponse = (sender: any) => {
    setEmailSucceeded(false);
    setEmailFailed(false);
    const userId = get(user, '_id', '');
    CommunitySurveyService.sendCommunityResourceData(userId, sender.data)
      .then(submitEmailSuccess)
      .catch(submitEmailFail);
  };

  const submitEmailSuccess = () => {
    setEmailSucceeded(true);
  };

  const submitEmailFail = () => {
    setEmailSucceeded(false);
    setEmailFailed(true);
  };

  const closeNotification = () => {
    setEmailSucceeded(false);
    setEmailFailed(false);
  };

  survey.onComplete.add(submitSurveyResponse);

  /*const isSurveyBeforePastYear = (communityResourcesSurveyDate) => {
    if (!communityResourcesSurveyDate) {
      //If no survey date, they have never taken the survey
      return true;
    }
    const communityResourcesSurveyDt = new Date(
      JSON.parse(communityResourcesSurveyDate),
    );

    const yearAgo = new Date();
    yearAgo.setFullYear(yearAgo.getFullYear() - 1);

    return communityResourcesSurveyDt.getTime() < yearAgo.getTime();
  };*/

  return (
    <React.Fragment>
      <NotificationComponent
        severity="success"
        isOpen={emailSucceeded}
        handleClose={closeNotification}
        message={t('notifications.emailSent')}
      />
      <NotificationComponent
        isOpen={emailFailed}
        handleClose={closeNotification}
        message={t('notifications.emailFail')}
      />
      <Box sx={styles.root}>
        <Box sx={styles.asideActions}>
          <MobileHeaderComponent />
        </Box>
        <div>
          <div style={styles.pageTitle}>
            <span>{t('communityResources.pageTitle')}</span>
          </div>
        </div>
        {/* {renderSurveyComponent()} */}
        <span>
          Complete these three questions to help us understand your needs and
          get connected to community resources :
        </span>
        <Survey model={survey} css={myCss} />
      </Box>
    </React.Fragment>
  );
}

export default CommunityResources;
