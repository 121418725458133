import { Theme } from '@mui/material';

export const styles = (theme: Theme) => ({
  root: {
    padding: '45px',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      padding: '15px',
    },
  },
  content: {
    display: 'flex',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      flexDirection: 'column',
    },
    height: '1800px',
    width: { mobile: '100%', tablet: '1000px' },
  },
  pageTitle: {
    fontSize: '3rem',
    marginBottom: '40px',
  },
  asideActions: {
    display: 'none',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      flexDirection: 'row',
      display: 'flex',
      flex: 'none',
      marginBottom: '23px',
    },
  },
  survey: {
    height: '1800px',
    width: { mobile: '100px', tablet: '1000px' },
  },
});

export default styles;
