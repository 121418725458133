import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import LandingContainer from 'containers/Landing/Landing.container';
import LabsContainer from 'containers/Labs/Labs.container';
import ImmunizationsContainer from 'containers/Immunizations/Immunizations.container';
import ContactContainer from 'containers/Contact/Contact.container';
import AboutContainer from 'containers/About/About.container';
import PrivacyPolicyContainer from 'containers/PrivacyPolicy/PrivacyPolicy.container';
import AccessibilityContainer from 'containers/Accessibility/Accessibility.container';
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute.component';
import SettingsContainer from 'containers/Settings/Settings.container';
import ProfileContainer from 'containers/Profile/Profile.container';
import MedicationsContainer from 'containers/Medications/Medications.container';
import PanelContainer from 'containers/Panel/Panel.container';
import PanelDetailContainer from 'containers/PanelDetail/PanelDetail.container';
import ImmunizationDetailContainer from 'containers/ImmunizationDetail/ImmunizationDetail.container';
import SupportContainer from 'containers/Support/Support.container';
import CommunityCare from 'containers/CommunityCare/CommunityCare.container';
import CommunityResources from 'containers/CommunityResources/CommunityResources.container';

export const ProtectedRoutesComponent = () => (
  <Switch>
    <ProtectedRoute path="/home" exact={true} component={LandingContainer} />
    <ProtectedRoute path="/labs" exact={true} component={LabsContainer} />
    <ProtectedRoute
      path="/labs/:panelId/:testCode/:testType"
      exact={true}
      component={PanelDetailContainer}
    />
    <ProtectedRoute
      path="/labs/:panelId"
      exact={true}
      component={PanelContainer}
    />
    <ProtectedRoute
      path="/immunizations/:recordId"
      exact={true}
      component={ImmunizationDetailContainer}
    />
    <ProtectedRoute
      path="/immunizations"
      exact={true}
      component={ImmunizationsContainer}
    />
    <ProtectedRoute
      path="/medications"
      exact={true}
      component={MedicationsContainer}
    />
    <ProtectedRoute path="/contact" exact={true} component={ContactContainer} />
    <ProtectedRoute path="/profile" exact={true} component={ProfileContainer} />
    <ProtectedRoute
      path="/settings"
      exact={true}
      component={SettingsContainer}
    />
    <ProtectedRoute path="/about" exact={true} component={AboutContainer} />
    <ProtectedRoute
      path="/privacy"
      exact={true}
      component={PrivacyPolicyContainer}
    />
    <ProtectedRoute
      path="/accessibility"
      exact={true}
      component={AccessibilityContainer}
    />
    <ProtectedRoute
      path="/community-care"
      exact={true}
      component={CommunityCare}
    />
    <ProtectedRoute
      path="/community-care/community-resources"
      exact={true}
      component={CommunityResources}
    />
    <ProtectedRoute path="/support" exact={true} component={SupportContainer} />
    <Redirect path="/" to="/home" />
  </Switch>
);

export default ProtectedRoutesComponent;
