import React from 'react';
import { Box, useTheme, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MobileHeaderComponent from 'components/MobileHeader/MobileHeader.component';

import useStyles from './CommunityCare.container.styles';
import CommunityCareImg from 'assets/images/communityCare.svg';

export default function CommunityCare() {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(theme);

  const renderResourceTile = () => (
    <Box component={Paper} elevation={3} sx={styles.resources}>
      <a
        style={styles.cardLink}
        rel="noreferrer"
        aria-label={t('communityCare.resourceTitle')}
        href="/community-care/community-resources"
      >
        <Box sx={styles.resourcesImg}>
          <img
            style={styles.img}
            src={CommunityCareImg}
            alt={t('immunizations.resourceImgAlt')}
          />
        </Box>
        <Box
          sx={{
            ...styles.details,
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <span>{t('communityCare.resourceTitle')}</span>
          <div style={styles.subtitle}>
            <span>{t('communityCare.resourceSubtitle')}</span>
          </div>
          <div style={styles.answer}>
            <span>{t('communityCare.answerQuestionsText')}</span>
          </div>
        </Box>
      </a>
    </Box>
  );

  return (
    <React.Fragment>
      <Box sx={styles.root}>
        <Box sx={styles.asideActions}>
          <MobileHeaderComponent />
        </Box>
        <div>
          <div style={styles.pageTitle}>
            <span>{t('communityCare.pageTitle')}</span>
          </div>
          <div style={styles.instruction}>
            <span>{t('communityCare.instruction')}</span>
          </div>
          {renderResourceTile()}
        </div>
      </Box>
    </React.Fragment>
  );
}
